<div class="page-box">
  <div class="content-box">
    <span class="mat-headline">无法识别的命名空间</span>
    <p class="mat-body-2">
      该标签尚未正式添加至 E-Hentai 标签系统。在提供翻译前，需要在
      <a href="https://forums.e-hentai.org/index.php?showtopic=246656" target="_blank">E-Hentai 论坛</a
      >发帖将该标签移动到合适的命名空间。
    </p>
  </div>
</div>
