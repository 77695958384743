<mat-toolbar color="accent" role="heading" class="mat-elevation-z8">
  <button id="back-button" mat-button (click)="goBack()" type="button" aria-label="返回">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <button id="home-button" mat-button [routerLink]="['/']" type="button" aria-label="主页">
    <span class="mat-title">EhTag Editor</span>
  </button>
  <span style="flex: 1 1 auto"></span>
  <app-user id="user-button"></app-user>
  <app-github-corner [href]="dbRepo.root" target="_blank"></app-github-corner>
</mat-toolbar>
<article>
  <router-outlet></router-outlet>
</article>
