@if (loading) {
  <button class="user-button" mat-button>
    <mat-spinner id="loading" [diameter]="32"></mat-spinner>
    正在登录
  </button>
} @else if (user) {
  <button class="user-button" mat-button [matMenuTriggerFor]="userMenu">
    <img [src]="user.avatar_url" alt="用户头像" />
    <span>{{ user.name }}</span>
  </button>
} @else {
  <button class="user-button" mat-button (click)="logIn()">
    <span class="iconfont" style="font-size: 1.8em">&#xe709; </span> 登录
  </button>
}

<mat-menu #userMenu="matMenu" xPosition="before">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="reviewSettings()">
      <mat-icon>settings</mat-icon>
      <span>管理链接</span>
    </button>
    <button mat-menu-item (click)="logOut()">
      <mat-icon>power_settings_new</mat-icon>
      <span>退出登录</span>
    </button>
  </ng-template>
</mat-menu>
